:root {
	--blink-time: 3s;
}
.blink {
	animation: var(--blink-time) blinker linear infinite;
	-webkit-animation: var(--blink-time) blinker linear infinite;
	-moz-animation: var(--blink-time) blinker linear infinite;
}

@-moz-keyframes blinker {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes blinker {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes blinker {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
